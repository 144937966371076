import React, { useEffect, useState, useCallback } from 'react'
import { useDispatch } from 'react-redux';
import actions from '../../redux/actions';
import { Helmet } from "react-helmet";
import Pagination from 'react-js-pagination';
import reportsLoader from '../../public/icons/loader.png';
import axios from 'axios';
import './UserLog.css'
import common from '../../common';
import _ from 'lodash';
import useDebounce from '../../hooks/debounce';


export const UserLog = () => {
  const dispatch = useDispatch();
  const [logResponse, setLogResponse] = useState()
  const { adminUserLog } = actions;
  const [startDate, setStartDate] = useState(() => {
    const currentDate = new Date();
    currentDate.setMonth(currentDate.getMonth() - 1);
    return currentDate.toISOString().split('T')[0];
  });
  const [endDate, setEndDate] = useState(() => {
    const currentDate = new Date();

    return currentDate.toISOString().split('T')[0];
  });
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState()
  const [exporting, setExporting] = useState(false)
  const [serchQuery, setSearchQuery] = useState(null)
  const [paginationDetail, setPaginationDetail] = useState({
    "current_page": 1,
    "total_page": 0,
    "count": 0,
    "page_size": 0
  })

  const debouncedQuery = useDebounce(serchQuery, 500)

  useEffect(() => {
    if (debouncedQuery) {
      // Call your API or perform the search logic here
      handleSearch()
    }
  }, [debouncedQuery]);




  const handlePageChange = (pageNumber) => {


    setPaginationDetail({ ...paginationDetail, 'current_page': pageNumber })
    fetchLog(startDate, endDate, pageNumber)

  }


  const fetchLog = async (startDate, endDate, current_page, serchQuery) => {
    setLoading(true)
    setError(null)
    try {
      const response = await dispatch(adminUserLog(startDate, endDate, current_page, serchQuery))
      console.log(response)
      setLogResponse(response.data)
      setError(response.error)
      setPaginationDetail(response.data.pagination)

    }
    catch (e) {

    }
    finally {
      setLoading(false)
    }


  }

  // useEffect(() => {
  //   if (startDate && endDate) {
  //     fetchLog(startDate, endDate, paginationDetail?.current_page)
  //   }
  // }, [startDate, endDate, paginationDetail?.current_page])



  const handleStartDateChange = (e) => {
    setStartDate(e.target.value);
  };

  const handleEndDateChange = (e) => {
    setEndDate(e.target.value);
  };

  const handleSearch = () => {
    if (startDate && endDate) {
      fetchLog(startDate, endDate, paginationDetail?.current_page, serchQuery)
    }
  }


  const handleExport = () => {
    setExporting(true)

    const formData = new FormData();
    formData.append('start_date', startDate);
    formData.append('end_date', endDate);
    formData.append('page', paginationDetail?.current_page);
    formData.append('is_download', 1);

    try {

      axios.post(`${common.apiBase}${common.adminUserLog}`,
        formData,
        {
          headers: {
            'Authorization': 'token ' + localStorage.getItem('token'),

          },

        }).then(res => {

          let filename = `unique-user-${startDate}-${endDate}.csv`;


          var hiddenElement = document.createElement('a');
          hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(res.data);
          // hiddenElement.target = '_blank';
          hiddenElement.download = filename;
          hiddenElement.click();
          setExporting(false)
        }).catch(err => {
          setExporting(false)
          console.error('error', err.message)
        })
    } catch (error) {
      console.error("Error in downloadReportClick:", error);
      setExporting(false)
    }

  }


  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  }
  return (
    <div className='admin-div'>
      <h1>Unique Users</h1>
      <Helmet>
        <title>Admin - Unique Users</title>
      </Helmet>
      <div className='select-range'>

        <label>
          Start Date:
          <input
            type="date"
            value={startDate}
            onChange={handleStartDateChange}
          />
        </label>
        <label>
          End Date:
          <input
            type="date"
            value={endDate}
            onChange={handleEndDateChange}
          />
        </label>
        <span className="action-btn">
          <button disabled={!(startDate && endDate)} onClick={handleSearch}>Search</button>
          <button disabled={!(startDate && endDate) || exporting} onClick={handleExport}> {exporting ? 'Exporting...' : 'Export'}</button>
        </span>
      </div>

      {loading && <div className='LoadingMessage'><img src={reportsLoader} /></div>}
      {logResponse &&
        <div style={{ overflow: 'auto', height: '80%' }} className="tableWrapper">
          <input
            value={serchQuery}
            placeholder="Search User"
            onChange={handleSearchChange}
          />
          <table>
            <thead>
              <tr>
                <th>Institute Name</th>
                <th>User Role</th>
                <th>User Count</th>
              </tr>
            </thead>
            <tbody>
              {logResponse && logResponse?.table_data?.map((row, index) => (
                <tr key={index}>
                  <td>{row?.institute_name}</td>
                  <td>{row?.user_role}</td>
                  <td>{row?.user_count}</td>
                </tr>
              ))}
            </tbody>
          </table>

          <table>
            <thead>
              <tr>
                <th>ID</th>
                <th>Username</th>
                <th>Name</th>
                <th>Institue Name</th>
                <th>Role</th>
                <th>Last Login</th>
              </tr>
            </thead>
            <tbody>


              {logResponse?.data?.map((row, index) => (
                <tr key={index}>
                  <td>{row?.user_id}</td>
                  <td>{row?.username}</td>
                  <td>{row?.name}</td>
                  <td>{row?.institute_name}</td>
                  <td>{row?.user_role}</td>
                  <td>{row?.latest_added_on?.split('T')[0]}</td>
                </tr>
              ))}
            </tbody>
          </table>

          <Pagination
            activePage={paginationDetail?.current_page}
            itemsCountPerPage={paginationDetail?.page_size}
            totalItemsCount={paginationDetail?.total_page * paginationDetail?.page_size}
            pageRangeDisplayed={10}
            onChange={handlePageChange}
          />

        </div>
      }

    </div>
  )
}
