import {
  LOADING,
  WIDGET_LIST,
  WIDGET_DATA
} from "../constants";

import config from '../../config.js';
import { store } from 'react-notifications-component';
import Service from '../../utils/Service';
import common from '../../common';

const getProfile = (center_id) => async dispatch => {
  dispatch({ type: LOADING, loading: true });
  const url = `${common.getProfile}${center_id}/`;
  const response = Service.get(url, (status, response) => {
    dispatch({ type: LOADING, loading: false });
    return response;
  }).catch(er => {
    dispatch({ type: LOADING, loading: false });
    //localStorage.clear();
    //window.location.href = config.logoutPage;
  })
  return response;
}

const wpKeyDecrypt = (key, referrer) => async dispatch => {
  console.log(key)
  console.log(referrer)
  Service.updateSecretKey();
  dispatch({ type: LOADING, loading: false });
  const url = `${common.wpKeyDecrypt}`;
  const data = new FormData();
  if (key !== null) {
    data.append('uid', key);
  }
  if (referrer !== null) {
    data.append('referrer', referrer);
  }
  const response = Service.post(url, data, (status, response) => {
    dispatch({ type: LOADING, loading: false });
    return response;
  }).catch(er => {
    dispatch({ type: LOADING, loading: false });
  })
  return response;
}

const getwpUserToken = () => async dispatch => {
  dispatch({ type: LOADING, loading: false });
  const url = `${common.wpUserToken}`;
  const response = Service.get(url, (status, response) => {
    dispatch({ type: LOADING, loading: false });
    return response;
  }).catch(er => {
    dispatch({ type: LOADING, loading: false });
    //localStorage.clear();
    //window.location.href = config.logoutPage;
  })
  return response;
}

const getWpCategoryList = () => async dispatch => {
  console.log("categoy");
  dispatch({ type: LOADING, loading: false });
  const url = `${common.wpList}`;
  const response = Service.wpGet(url, (status, response) => {
    dispatch({ type: LOADING, loading: false });
    return response;
  }).catch(er => {
    console.log("32432432432432" + er.message);
    dispatch({ type: LOADING, loading: false });
    return er
    //window.location.href = config.logoutPage;
  })
  return response;
}

const getCCS = (type, parent) => async dispatch => {
  let url = '';
  if (type === 1) {
    url = `${common.getCCS}?type=${type}`;
  }
  else {
    url = `${common.getCCS}?type=${type}&parent=${parent}`;
  }
  const response = Service.get(url, (status, response) => {
    dispatch({ type: LOADING, loading: false });
    return response;
  }).catch(er => { dispatch({ type: LOADING, loading: false }); })
  return response;
}

const getCenterList = () => async dispatch => {
  dispatch({ type: LOADING, loading: true });
  const url = `${common.centerList}`;
  const response = Service.get(url, (status, response) => {
    dispatch({ type: LOADING, loading: false });
    return response;
  }).catch(er => { dispatch({ type: LOADING, loading: false }); })
  return response;
}

const getAdminList = () => async dispatch => {
  dispatch({ type: LOADING, loading: false });
  const url = `${common.adminList}`;
  const response = Service.get(url, (status, response) => {
    dispatch({ type: LOADING, loading: false });
    return response;
  }).catch(er => { dispatch({ type: LOADING, loading: false }); })
  return response;
}

const getClassList = () => async dispatch => {
  dispatch({ type: LOADING, loading: true });
  const url = `${common.classList}`;
  const response = Service.get(url, (status, response) => {
    dispatch({ type: LOADING, loading: false });
    return response;
  }).catch(er => { dispatch({ type: LOADING, loading: false }); })
  return response;
}

const getClassList2 = () => async dispatch => {
  const url = `${common.classList}`;
  const response = Service.get(url, (status, response) => {
    return response;
  }).catch(er => { dispatch({ type: LOADING, loading: false }); })
  return response;
}
const getElectiveStudentList = (center_id, class_id, section_id) => async dispatch => {
  const url = `${common.electiveStudentList}`;
  const response = Service.post(url, { center_id, class_id, section_id }, (status, response) => {
    return response;
  }).catch(er => { dispatch({ type: LOADING, loading: false }); })
  return response;
}
const removeElectiveStudent = (student_list) => async dispatch => {
  const url = `${common.removeElectiveStudent}`;
  const response = Service.post(url, { student_list }, (status, response) => {
    return response;
  }).catch(er => { dispatch({ type: LOADING, loading: false }); })
  return response;
}

const getMasterSubjects = () => async dispatch => {
  dispatch({ type: LOADING, loading: true });
  const url = `${common.masterSubjects}`;
  const response = Service.get(url, (status, response) => {
    dispatch({ type: LOADING, loading: false });
    return response;
  }).catch(er => { dispatch({ type: LOADING, loading: false }); })
  return response;
}

const getSubjectList = (center_id, class_id) => async dispatch => {
  dispatch({ type: LOADING, loading: true });
  const url = `${common.subjectList}?center_id=${center_id}&class_id=${class_id}`;
  const response = Service.get(url, (status, response) => {
    dispatch({ type: LOADING, loading: false });
    return response;
  }).catch(er => { dispatch({ type: LOADING, loading: false }); })
  return response;
}

const getSubjectList2 = (center_id, class_id) => async dispatch => {
  const url = `${common.subjectList}?center_id=${center_id}&class_id=${class_id}`;
  const response = Service.get(url, (status, response) => {
    return response;
  }).catch(er => { dispatch({ type: LOADING, loading: false }); })
  return response;
}

const getFacultyList = (center_id, show_ext_dt) => async dispatch => {
  dispatch({ type: LOADING, loading: true });
  const url = `${common.facultyList}`;
  const response = Service.post(url, { center_id, show_ext_dt }, (status, response) => {
    dispatch({ type: LOADING, loading: false });
    return response;
  }).catch(er => { dispatch({ type: LOADING, loading: false }); })
  return response;
}

const getSectionList = (center_id, class_id, show_all, page) => async dispatch => {
  //dispatch({ type: LOADING, loading: true });

  let url = '';

  if (page && page == localStorage.getItem("page_url"))
    url = `${common.sectionList}?center_id=${center_id}&classs_id=${class_id}&show_all=${show_all}`;
  else
    url = `${common.sectionList}?center_id=${center_id}&classs_id=${class_id}`;

  const response = Service.get(url, (status, response) => {
    //dispatch({ type: LOADING, loading: false });
    return response;
  }).catch(er => { dispatch({ type: LOADING, loading: false }); })
  return response;
}

const getSectionList2 = (center_id, class_id) => async dispatch => {
  const url = `${common.sectionList}?center_id=${center_id}&classs_id=${class_id}`;
  const response = Service.get(url, (status, response) => {
    return response;
  }).catch(er => { dispatch({ type: LOADING, loading: false }); })
  return response;
}

const getStudentList = (center_id, class_id, section_id, page) => async dispatch => {
  dispatch({ type: LOADING, loading: true });

  const url = `${common.studentList}?page=${page}`;
  const response = Service.post(url, { center_id, class_id, section_id }, (status, response) => {
    dispatch({ type: LOADING, loading: false });
    return response;
  }).catch(er => { dispatch({ type: LOADING, loading: false }); })
  return response;
}

const getStudentListActiveInactive = (center_id, class_id, section_id, page, is_active) => async dispatch => {
  dispatch({ type: LOADING, loading: true });

  const url = `${common.studentList}?page=${page}`;
  const response = Service.post(url, { center_id, class_id, section_id, is_active }, (status, response) => {
    dispatch({ type: LOADING, loading: false });
    return response;
  }).catch(er => { dispatch({ type: LOADING, loading: false }); })
  return response;
}


const getStudentListNotifications = (center_id, class_id, section_id, page, assessment_id) => async dispatch => {
  dispatch({ type: LOADING, loading: true });

  const url = `${common.studentList}?page=${page}`;
  const response = Service.post(url, { center_id, class_id, section_id, assessment_id }, (status, response) => {
    dispatch({ type: LOADING, loading: false });
    return response;
  }).catch(er => { dispatch({ type: LOADING, loading: false }); })
  return response;
}


const getStudentListAssignmentNotifications = (center_id, class_id, section_id, page, assignment_id) => async dispatch => {
  dispatch({ type: LOADING, loading: true });

  const url = `${common.studentList}?page=${page}`;
  const response = Service.post(url, { center_id, class_id, section_id, assignment_id }, (status, response) => {
    dispatch({ type: LOADING, loading: false });
    return response;
  }).catch(er => { dispatch({ type: LOADING, loading: false }); })
  return response;
}

const getAssessment = (id, ifloading) => async dispatch => {
  dispatch({ type: LOADING, loading: ifloading });
  const url = `${common.assessmentData}${id}/`;
  const response = Service.get(url, (status, response) => {
    dispatch({ type: LOADING, loading: false });
    return response;
  }).catch(er => { dispatch({ type: LOADING, loading: false }); })
  return response;
}

const getAssessmentList = (center) => async dispatch => {
  dispatch({ type: LOADING, loading: true });
  const url = `${common.assessmentList}`;
  const response = Service.post(url, { center }, (status, response) => {
    dispatch({ type: LOADING, loading: false });
    return response;
  }).catch(er => { dispatch({ type: LOADING, loading: false }); })
  return response;
}

const getDPPList = (subject_id, batch_id, start_date, days, direction, if_loading, center) => async dispatch => {
  dispatch({ type: LOADING, loading: if_loading });
  const url = `${common.dppList}`;
  const response = Service.post(url, { subject_id, batch_id, start_date, days, direction, center }, (status, response) => {
    dispatch({ type: LOADING, loading: false });
    return response;
  }).catch(er => { dispatch({ type: LOADING, loading: false }); })
  return response;
}

const getPracticeSections = (data_type, center, assessment_id) => async dispatch => {
  dispatch({ type: LOADING, loading: true });
  const url = `${common.dppList}`;
  const data = new FormData();
  data.append('data_type', data_type);
  data.append('center', center);
  data.append('assessment_id', assessment_id);
  const response = Service.post(url, data, (status, response) => {
    dispatch({ type: LOADING, loading: false });
    return response;
  }).catch(er => { dispatch({ type: LOADING, loading: false }); })
  return response;
}

const getAssessmentSectionList = (assessment_id, ifloading) => async dispatch => {
  dispatch({ type: LOADING, loading: ifloading });
  const url = `${common.assessmentSectionList}?assessment_id=${assessment_id}`;
  const response = Service.get(url, (status, response) => {
    dispatch({ type: LOADING, loading: false });
    return response;
  }).catch(er => { dispatch({ type: LOADING, loading: false }); })
  return response;
}

const getTOC = () => async dispatch => {
  dispatch({ type: LOADING, loading: true });
  const url = `${common.getTOC}`;
  const response = Service.get(url, (status, response) => {
    dispatch({ type: LOADING, loading: false });
    return response;
  }).catch(er => { dispatch({ type: LOADING, loading: false }); })
  return response;
}

const getUnitTOC = (subject_id) => async dispatch => {
  dispatch({ type: LOADING, loading: true });
  const url = `${common.getContentTOC}?subject=${subject_id}`;
  const response = Service.get(url, (status, response) => {
    dispatch({ type: LOADING, loading: false });
    return response;
  }).catch(er => { dispatch({ type: LOADING, loading: false }); })
  return response;
}

const getChapterTOC = (subject_id, id) => async dispatch => {
  dispatch({ type: LOADING, loading: true });
  const url = `${common.getContentTOC}?subject=${subject_id}&id=${id}`;
  const response = Service.get(url, (status, response) => {
    dispatch({ type: LOADING, loading: false });
    return response;
  }).catch(er => { dispatch({ type: LOADING, loading: false }); })
  return response;
}

const getContentDetails = (id) => async dispatch => {
  dispatch({ type: LOADING, loading: true });
  const url = `${common.contentDetails}${id}/`;
  const response = Service.get(url, (status, response) => {
    dispatch({ type: LOADING, loading: false });
    return response;
  }).catch(er => { dispatch({ type: LOADING, loading: false }); })
  return response;
}

const getLectureList = (start_date, days, direction, if_loading, center, classs, section, user_id, is_faculty) => async dispatch => {
  console.log("adsadsad" + is_faculty)
  dispatch({ type: LOADING, loading: true });
  var url = `${common.lectureList}?center=${center}`;
  if (user_id && is_faculty == "true") var url = `${common.lectureList}?center=${center}&faculty=${user_id}`;

  if (classs.length > 0) {
    url = url + `&class=${classs}`;
  }
  if (section.length > 0) {
    url = url + `&section=${section}`;
  }


  const data_range = 15;

  const response = Service.post(url, { start_date, days, data_range, direction }, (status, response) => {
    dispatch({ type: LOADING, loading: false });
    return response;
  }).catch(er => { dispatch({ type: LOADING, loading: false }); })
  return response;
}

const getStudentDashboard = () => async dispatch => {
  dispatch({ type: LOADING, loading: true });
  const url = `${common.studentDashboard}`;
  const response = Service.get(url, (status, response) => {
    dispatch({ type: LOADING, loading: false });
    return response;
  }).catch(er => {
    dispatch({ type: LOADING, loading: false });
    return er;
  })
  return response;
}

const getFacultyDashboard = () => async dispatch => {
  dispatch({ type: LOADING, loading: true });
  const url = `${common.facultyDashboard}`;
  const response = Service.get(url, (status, response) => {
    dispatch({ type: LOADING, loading: false });
    return response;
  }).catch(er => { dispatch({ type: LOADING, loading: false }); })
  return response;
}

const getExerciseList = (start_date, days, direction, if_loading) => async dispatch => {
  dispatch({ type: LOADING, loading: if_loading });
  const url = `${common.getExerciseList}`
  const response = Service.post(url, {}, (status, response) => {
    dispatch({ type: LOADING, loading: false });
    return response;
  }).catch(er => { dispatch({ type: LOADING, loading: false }); })
  return response;
};

const getFacultyExerciseList = (subject_id, batch_id, start_date, days, direction, if_loading) => async dispatch => {
  dispatch({ type: LOADING, loading: if_loading });
  const url = `${common.getFacultyDPPList}`;
  const data_range = 15;
  const data_type = 1;

  if (subject_id || batch_id) {
    const response = Service.post(url, { data_type, subject_id, batch_id }, (status, response) => {
      dispatch({ type: LOADING, loading: false });
      return response;
    }).catch(er => { dispatch({ type: LOADING, loading: false }); })
    return response;
  } else {
    const response = Service.post(url, { data_type }, (status, response) => {
      dispatch({ type: LOADING, loading: false });
      return response;
    }).catch(er => { dispatch({ type: LOADING, loading: false }); })
    return response;
  }

};

const getStudentDPPList = (start_date, days, direction, if_loading, batch_id) => async dispatch => {
  dispatch({ type: LOADING, loading: if_loading });
  const url = `${common.getDPPList}`;
  const data_range = 15;
  const response = Service.post(url, { start_date, days, data_range, direction, batch_id }, (status, response) => {
    dispatch({ type: LOADING, loading: false });
    return response;
  }).catch(er => { dispatch({ type: LOADING, loading: false }); })
  return response;
};

const getFacultyDPPList = (subject_id, batch_id, start_date, days, direction, if_loading) => async dispatch => {
  dispatch({ type: LOADING, loading: if_loading });
  const url = `${common.getFacultyDPPList}`;
  const data_range = 15;
  const data_type = 2;
  const response = Service.post(url, { data_type, subject_id, batch_id, start_date, days, data_range, direction }, (status, response) => {
    dispatch({ type: LOADING, loading: false });
    return response;
  }).catch(er => { dispatch({ type: LOADING, loading: false }); })
  return response;
}

const getStudentLectureList = (start_date, days, direction, if_loading) => async dispatch => {
  dispatch({ type: LOADING, loading: if_loading });
  const url = `${common.studentLectures}`;
  const data_range = 15;
  const response = Service.post(url, { start_date, days, data_range, direction }, (status, response) => {
    dispatch({ type: LOADING, loading: false });
    return response;
  }).catch(er => { dispatch({ type: LOADING, loading: false }); })
  return response;
};

const getFacultyLectureList = (subject_id, batch_id, start_date, days, direction, if_loading) => async dispatch => {
  dispatch({ type: LOADING, loading: if_loading });
  const url = `${common.facultyLectures}`;
  const data_range = 15;
  const response = Service.post(url, { subject_id, batch_id, start_date, days, data_range, direction }, (status, response) => {
    dispatch({ type: LOADING, loading: false });
    return response;
  }).catch(er => { dispatch({ type: LOADING, loading: false }); })
  return response;
}

const getStudentTOC = (subject_id, is_archive) => async dispatch => {
  dispatch({ type: LOADING, loading: true });
  const url = `${common.studentTOC}`;
  const response = Service.post(url, { subject_id, is_archive }, (status, response) => {
    dispatch({ type: LOADING, loading: false });
    return response;
  }).catch(er => { dispatch({ type: LOADING, loading: false }); })
  return response;
}

const getStudentDoubtList = (subject_id, by_me, page, type) => async dispatch => {
  dispatch({ type: LOADING, loading: true });
  var url = '';

  if (parseInt(subject_id) !== 0 && parseInt(subject_id) != null) {
    url = `${common.studentDoubtList}?subject_id=${subject_id}&by_me=${by_me}&page=${page}&type=${type}`;
  }
  else {
    url = `${common.studentDoubtList}?by_me=${by_me}&page=${page}&type=${type}`;
  }
  const response = Service.get(url, (status, response) => {
    dispatch({ type: LOADING, loading: false });
    return response;
  }).catch(er => { dispatch({ type: LOADING, loading: false }); })
  return response;
}

const getFacultyDoubtList = (subject_id, batch_id, by_me, page, type = 1) => async dispatch => {
  dispatch({ type: LOADING, loading: true });
  console.log("yoru vauue  2", subject_id, batch_id, by_me, page, type)
  var url = '';
  if (parseInt(subject_id) !== 0) {
    if (parseInt(batch_id) !== 0) {
      url = `${common.facultyDoubtList}?subject_id=${subject_id}&batch_id=${batch_id}&by_me=${by_me}&page=${page}&type=${type}`;
    }
    else {
      url = `${common.facultyDoubtList}?subject_id=${subject_id}&by_me=${by_me}&page=${page}&type=${type}`;
    }
  }
  else {
    if (parseInt(batch_id) !== 0) {
      url = `${common.facultyDoubtList}?batch_id=${batch_id}&by_me=${by_me}&page=${page}&type=${type}`;
    }
    else {
      url = `${common.facultyDoubtList}?by_me=${by_me}&page=${page}&type=${type}`;
    }
  }
  const response = Service.get(url, (status, response) => {
    dispatch({ type: LOADING, loading: false });
    return response;
  }).catch(er => { dispatch({ type: LOADING, loading: false }); })
  return response;
}


const getBlogPost = (subject_id, class_id, pageNumber, type = 3) => async dispatch => {
  dispatch({ type: LOADING, loading: true });
  var url;
  if (subject_id) {
    url = `${common.facultyDoubtList}?by_me=0&type=${type}&subject_id=${subject_id}&class_id=${class_id}&page_number=${pageNumber}`;
  }
  else url = `${common.facultyDoubtList}?by_me=0&type=${type}&page_number=${pageNumber}`;

  const response = Service.get(url, (status, response) => {
    dispatch({ type: LOADING, loading: false });
    return response;
  }).catch(er => { dispatch({ type: LOADING, loading: false }); })
  return response;
}

const getStudentDoubtResponses = (doubt_forum_id) => async dispatch => {
  dispatch({ type: LOADING, loading: true });
  const url = `${common.studentDoubtList}?doubt_forum_id=${doubt_forum_id}`;
  const response = Service.get(url, (status, response) => {
    dispatch({ type: LOADING, loading: false });
    return response;
  }).catch(er => { dispatch({ type: LOADING, loading: false }); })
  return response;
}

const getFacultyDoubtResponses = (doubt_forum_id) => async dispatch => {
  dispatch({ type: LOADING, loading: true });
  const url = `${common.facultyDoubtList}?doubt_forum_id=${doubt_forum_id}`;
  const response = Service.get(url, (status, response) => {
    dispatch({ type: LOADING, loading: false });
    return response;
  }).catch(er => { dispatch({ type: LOADING, loading: false }); })
  return response;
}

const getAssessmentReviewData = (content_id) => async dispatch => {
  dispatch({ type: LOADING, loading: true });
  const url = `${common.getAssessmentReview}`;
  const response = Service.post(url, { content_id }, (status, response) => {
    dispatch({ type: LOADING, loading: false });
    return response
  }).catch(er => {
    dispatch({ type: LOADING, loading: false });
    console.error('error', er.message)
  })
  return response;
};

const getAssessmentReviewAnalyticsData = (attempt_id, question_id) => async dispatch => {
  dispatch({ type: LOADING, loading: true });
  const url = `${common.getAssessmentReviewAnalytics}`;
  const response = Service.post(url, { attempt_id, question_id }, (status, response) => {
    dispatch({ type: LOADING, loading: false });
    return response
  }).catch(er => {
    dispatch({ type: LOADING, loading: false });
    console.error('error', er.message)
  })
  return response;
};

const getAssessmentFacultyReviewData = (content_id, batch_id) => async dispatch => {
  dispatch({ type: LOADING, loading: true });
  const url = `${common.getAssessmentFacultyReview}`
  const response = Service.post(url, { content_id, batch_id }, (status, response) => {
    dispatch({ type: LOADING, loading: false });
    return response
  }).catch(er => {
    dispatch({ type: LOADING, loading: false });
  })
  return response;
};

const getScorecard = (content_id) => async dispatch => {
  dispatch({ type: LOADING, loading: true });
  const url = `${common.getScorecard}`;
  const response = Service.post(url, { content_id }, (status, response) => {
    dispatch({ type: LOADING, loading: false });
    return response.data
  }).catch(er => {
    dispatch({ type: LOADING, loading: false });
    console.error('error', er.message)
  })
  return response;
};

const getSearchList = (search) => async dispatch => {
  dispatch({ type: LOADING, loading: false });
  const url = `${common.searchList}?search=${search}`;
  const response = Service.get(url, (status, response) => {
    dispatch({ type: LOADING, loading: false });
    return response;
  }).catch(er => {
    dispatch({ type: LOADING, loading: false });
    console.error('error', er.message)
  })
  return response;
};

const userSearchList = (key, page) => async dispatch => {
  dispatch({ type: LOADING, loading: false });
  const url = `${common.userSearchList}?search=${key}&page=${page}`;
  const response = Service.get(url, (status, response) => {
    dispatch({ type: LOADING, loading: false });
    return response;
  }).catch(er => {
    dispatch({ type: LOADING, loading: false });
    console.error('error', er.message)
  })
  return response;
};


const adminSearchList = (search, context) => async dispatch => {
  dispatch({ type: LOADING, loading: false });
  const url = `${common.adminSearchList}?search=${search}&context=${context}`;
  const response = Service.get(url, (status, response) => {
    dispatch({ type: LOADING, loading: false });
    return response;
  }).catch(er => {
    dispatch({ type: LOADING, loading: false });
    console.error('error', er.message)
  })
  return response;
};

const getFacultyScorecard = (content_id, batch_id) => async dispatch => {
  dispatch({ type: LOADING, loading: true });
  const url = `${common.getFacultyScorecard}`
  const response = Service.post(url, { content_id, batch_id }, (status, response) => {
    dispatch({ type: LOADING, loading: false });
    return response.data
  }).catch(er => {
    dispatch({ type: LOADING, loading: false });
    console.error('error', er.message)
  })
  return response;
};

const getQuestionsList = (page) => async dispatch => {
  dispatch({ type: LOADING, loading: true });
  const url = `${common.getQuestionsList}?page=${page}`;
  const response = Service.get(url, (status, response) => {
    dispatch({ type: LOADING, loading: false });
    return response;
  }).catch(er => { dispatch({ type: LOADING, loading: false }); })
  return response;
}

const qodlist = () => async dispatch => {
  dispatch({ type: LOADING, loading: true });
  const url = `${common.qodlist}`;
  const response = Service.get(url, (status, response) => {
    dispatch({ type: LOADING, loading: false });
    return response;
  }).catch(er => { dispatch({ type: LOADING, loading: false }); })
  return response;
}

const questionBankSearch = (key) => async dispatch => {
  dispatch({ type: LOADING, loading: false });
  const url = `${common.questionBankSearch}?${key}`;
  const response = Service.get(url, (status, response) => {
    dispatch({ type: LOADING, loading: false });
    return response;
  }).catch(er => { dispatch({ type: LOADING, loading: false }); })
  return response;
}


const getMyCoachList = () => async dispatch => {
  dispatch({ type: LOADING, loading: true });
  const url = `${common.xpMyCoachListing}`;
  const response = Service.get(url, (status, response) => {
    dispatch({ type: LOADING, loading: false });
    return response;
  }).catch(er => { dispatch({ type: LOADING, loading: false }); })
  return response;
}

const getCertificationBannerList = (type, center) => async dispatch => {
  dispatch({ type: LOADING, loading: true });

  let url = ''
  const type_val = type !== undefined ? type : "";

  url = `${common.xpCertificationBannerListing}?type=${type_val}`;
  if (center != undefined)
    url = `${common.xpCertificationBannerListing}?type=${type_val}&center=${center}`;

  const response = Service.get(url, (status, response) => {
    dispatch({ type: LOADING, loading: false });
    return response;
  }).catch(er => { dispatch({ type: LOADING, loading: false }); })
  return response;
}

const qodStudentList = () => async dispatch => {
  dispatch({ type: LOADING, loading: false });
  const url = `${common.qodStudentList}`;
  const response = Service.get(url, (status, response) => {
    dispatch({ type: LOADING, loading: false });
    return response;
  }).catch(er => { dispatch({ type: LOADING, loading: false }); })
  return response;
}

const studentQodListing = (date, days, direction, if_loading) => async dispatch => {
  dispatch({ type: LOADING, loading: false });
  const url = `${common.studentQodListing}`;
  let data = new FormData();

  if (date) {
    data.append('start_date', date);
  }
  if (direction) {
    data.append('direction', direction);
  }
  const response = Service.post(url, data, (status, response) => {
    dispatch({ type: LOADING, loading: false });
    return response;
  }).catch(er => { dispatch({ type: LOADING, loading: false }); })
  return response;
}

const getGoalQuestionList = () => async dispatch => {
  dispatch({ type: LOADING, loading: true });
  const url = `${common.xpGoalQuestionList}`;
  const response = Service.get(url, (status, response) => {
    dispatch({ type: LOADING, loading: false });
    return response;
  }).catch(er => { dispatch({ type: LOADING, loading: false }); })
  return response;
}

const getMentorInterest = () => async dispatch => {
  dispatch({ type: LOADING, loading: true });
  const url = `${common.xpGetMentorshipInterest}`;
  const response = Service.get(url, (status, response) => {
    dispatch({ type: LOADING, loading: false });
    return response;
  }).catch(er => { dispatch({ type: LOADING, loading: false }); })
  return response;
}

const getTellInterest = () => async dispatch => {
  dispatch({ type: LOADING, loading: true });
  const url = `${common.xpGetTellInterest}`;
  const response = Service.get(url, (status, response) => {
    dispatch({ type: LOADING, loading: false });
    return response;
  }).catch(er => { dispatch({ type: LOADING, loading: false }); })
  return response;
}


const userdetail = (id) => async dispatch => {
  dispatch({ type: LOADING, loading: false });
  const url = `${common.userdetail}/?user_id=${id}`;

  const response = Service.get(url, (status, response) => {
    dispatch({ type: LOADING, loading: false });
    return response;
  }).catch(er => { dispatch({ type: LOADING, loading: false }); })
  return response;
}

const myCoursesList = () => async dispatch => {
  dispatch({ type: LOADING, loading: false });
  const url = `${common.myCoursesList}`;

  const response = Service.get(url, (status, response) => {
    dispatch({ type: LOADING, loading: false });
    return response;
  }).catch(er => { dispatch({ type: LOADING, loading: false }); })
  return response;
}

const openCourseSubjects = (level) => async dispatch => {
  dispatch({ type: LOADING, loading: false });
  const url = `${common.openCourseSubjects}?level=${level}`;

  const response = Service.get(url, (status, response) => {
    dispatch({ type: LOADING, loading: false });
    return response;
  }).catch(er => { dispatch({ type: LOADING, loading: false }); })
  return response;
}

const openCourseLevels = () => async dispatch => {
  dispatch({ type: LOADING, loading: false });
  const url = `${common.openCourseLevels}`;

  const response = Service.get(url, (status, response) => {
    dispatch({ type: LOADING, loading: false });
    return response;
  }).catch(er => { dispatch({ type: LOADING, loading: false }); })
  return response;
}

const instituteWidgetList = () => async dispatch => {
  dispatch({ type: LOADING, loading: true });
  const url = `${common.instituteWidgetList}`;
  const response = Service.get(url, (status, response) => {
    dispatch({ type: WIDGET_LIST, widgets: response.data });
    dispatch({ type: LOADING, loading: false });
    return response;

  }).catch(er => {
    dispatch({ type: LOADING, loading: false })
    return er
  })
  return response;
}

const getWidgetDataById = (id) => async dispatch => {
  dispatch({ type: LOADING, loading: true });
  const url = `${common.getWidgetDataById}?widget_id=${id}`;
  const response = Service.get(url, (status, response) => {
    dispatch({ type: WIDGET_DATA, widgetsOnId: response.data });
    dispatch({ type: LOADING, loading: false });
    return response;
  }).catch(er => dispatch({ type: LOADING, loading: false }))
  return response;
}

const fetchPromotionState = (id) => async dispatch => {
  dispatch({ type: LOADING, loading: true });
  const url = `${common.fetchPromotionState}?task_id=${id}`;
  const response = Service.get(url, (status, response) => {
    dispatch({ type: LOADING, loading: false });
    return response;
  }).catch(er => dispatch({ type: LOADING, loading: false }))
  return response;
}

const programList = (center_id) => async dispatch => {
  dispatch({ type: LOADING, loading: false });
  const url = `${common.programList}?center_id=${center_id}`;

  const response = Service.get(url, (status, response) => {
    dispatch({ type: LOADING, loading: false });
    return response;
  }).catch(er => dispatch({ type: LOADING, loading: false }))
  return response;
}
const sessionList = (center_id) => async dispatch => {
  dispatch({ type: LOADING, loading: false });
  const url = `${common.sessionList}?center=${center_id}`;

  const response = Service.get(url, (status, response) => {
    dispatch({ type: LOADING, loading: false });
    return response;
  }).catch(er => dispatch({ type: LOADING, loading: false }))
  return response;
}

const assessmentQuestionDelete = (assessment_section_id, question_id) => async dispatch => {
  dispatch({ type: LOADING, loading: false });
  const url = `${common.assessmentQuestionDelete}?assessment_section_id=${assessment_section_id}&question_id=${question_id}`;

  const response = Service.get(url, (status, response) => {
    dispatch({ type: LOADING, loading: false });
    if (response != undefined) {
      store.addNotification({
        title: 'Success',
        // message: response.data[0],
        message: `${response.error}`,
        type: 'success',
        container: 'top-right',
        dismiss: {
          duration: 3000
        }
      })
    }
    window.location.reload()
    //return response;
  }).catch(er => {
    dispatch({ type: LOADING, loading: false })

    store.addNotification({
      title: 'Error',
      message: `${er.message}`,
      type: 'danger',
      container: 'top-right',
      dismiss: {
        duration: 3000
      }
    })

  })
  return response;
}

const categoryList = () => async dispatch => {
  dispatch({ type: LOADING, loading: false });
  const url = `${common.categoryList}`;

  const response = Service.get(url, (status, response) => {
    dispatch({ type: LOADING, loading: false });
    return response;
  }).catch(er => dispatch({ type: LOADING, loading: false }))
  return response;
}

const widgetDelete = (id) => async dispatch => {
  dispatch({ type: LOADING, loading: true });
  const url = `${common.widgetDelete}/${id}`;
  const response = Service.get(url, (status, response) => {
    dispatch({ type: LOADING, loading: false });
    store.addNotification({
      title: 'Success',
      message: `${response.error}`,
      type: 'success',
      container: 'top-right',
      dismiss: {
        duration: 3000,
      }
    })

    //window.location.reload()

  }).catch(er => { dispatch({ type: LOADING, loading: false }); })
  return response;
}

const widgetList = () => async dispatch => {
  dispatch({ type: LOADING, loading: false });
  const url = `${common.widgetList}`;

  const response = Service.get(url, (status, response) => {
    dispatch({ type: LOADING, loading: false });
    return response;
  }).catch(er => dispatch({ type: LOADING, loading: false }))
  return response;
}

const formList = () => async dispatch => {
  dispatch({ type: LOADING, loading: false });
  const url = `${common.formList}`;

  const response = Service.get(url, (status, response) => {
    dispatch({ type: LOADING, loading: false });
    return response;
  }).catch(er => dispatch({ type: LOADING, loading: false }))
  return response;
}

const masterSettingList = () => async dispatch => {
  dispatch({ type: LOADING, loading: false });
  const url = `${common.masterSettingList}`;

  const response = Service.get(url, (status, response) => {
    dispatch({ type: LOADING, loading: false });
    return response;
  }).catch(er => dispatch({ type: LOADING, loading: false }))
  return response;
}

const settingList = () => async dispatch => {
  dispatch({ type: LOADING, loading: false });
  const url = `${common.settingList}`;

  const response = Service.get(url, (status, response) => {
    dispatch({ type: LOADING, loading: false });
    return response;
  }).catch(er => dispatch({ type: LOADING, loading: false }))
  return response;
}

const searchUser = (email) => async dispatch => {
  dispatch({ type: LOADING, loading: false });
  const url = `${common.searchUser}?search=${email}`;

  const response = Service.get(url, (status, response) => {
    dispatch({ type: LOADING, loading: false });
    return response;
  }).catch(er => dispatch({ type: LOADING, loading: false }))
  return response;
}

const wpUserLink = (user_id, center_id) => async dispatch => {
  dispatch({ type: LOADING, loading: false });
  const url = `${common.wpUserLink}?user_id=${user_id}&center_id=${center_id}`;

  const response = Service.get(url, (status, response) => {
    dispatch({ type: LOADING, loading: false });
    store.addNotification({
      title: 'Success',
      // message: response.msg,
      message: `${response.error}`,
      type: 'success',
      container: 'top-right',
      dismiss: {
        duration: 3000
      }
    })
    return response;
  }).catch(er => dispatch({ type: LOADING, loading: false }))
  return response;
}

const getAssessmentsTypesList = () => async dispatch => {
  dispatch({ type: LOADING, loading: false });
  const url = `${common.getAssessmentsTypesList}`;
  const response = Service.get(url, (status, response) => {
    dispatch({ type: LOADING, loading: false });
    return response;
  }).catch(er => {
    dispatch({ type: LOADING, loading: false });
    console.error('error', er.message)
  })
  return response;
};

const callprofileApI = (token) => async dispatch => {
  dispatch({ type: LOADING, loading: false });
  const url = `${common.callprofileApI(token)}`;
  const response = Service.get(url, (status, response) => {
    dispatch({ type: LOADING, loading: false });
    return response;
  }).catch(er => {
    dispatch({ type: LOADING, loading: false });
    console.error('error', er.message)
  })
  return response;
};

const createLeadUser = (secret) => async dispatch => {
  dispatch({ type: LOADING, loading: false });
  const url = `${common.createLeadUser}?secret=${secret}`;
  const response = Service.get(url, (status, response) => {
    dispatch({ type: LOADING, loading: false });
    return response;
  }).catch(er => {
    dispatch({ type: LOADING, loading: false });
    console.error('error', er.message)
  })
  return response;
};

const switchClassTags = () => async dispatch => {
  dispatch({ type: LOADING, loading: false });
  const url = `${common.switchClassTags}`;
  const response = Service.get(url, (status, response) => {
    dispatch({ type: LOADING, loading: false });
    return response;
  }).catch(er => {
    dispatch({ type: LOADING, loading: false });
    console.error('error', er.message)
  })
  return response;
};


const deleteBanner = (bannerid) => async dispatch => {
  dispatch({ type: LOADING, loading: false });
  const url = `${common.deleteBanner}/${bannerid}`;
  const response = Service.get(url, (status, response) => {
    dispatch({ type: LOADING, loading: false });
    window.location.reload()
    return response;
  }).catch(er => {
    dispatch({ type: LOADING, loading: false });
    console.error('error', er.message)
  })
  return response;
};

const getBatchHistory = (user_id) => async dispatch => {
  dispatch({ type: LOADING, loading: false });
  const url = `${common.getBatchHistory}?user_id=${user_id}`;
  const response = Service.get(url, (status, response) => {
    dispatch({ type: LOADING, loading: false });
    return response;
  }).catch(er => {
    dispatch({ type: LOADING, loading: false });
    console.error('error', er.message)
  })
  return response;
};

const getUserPortfolio = (user_id) => async dispatch => {
  dispatch({ type: LOADING, loading: false });
  const url = `${common.getUserPortfolio}?user_id=${user_id}`;
  const response = Service.get(url, (status, response) => {
    dispatch({ type: LOADING, loading: false });
    return response;
  }).catch(er => {
    dispatch({ type: LOADING, loading: false });
    console.error('error', er.message)
  })
  return response;
};

const facultyDetailsByID = (id) => async dispatch => {
  dispatch({ type: LOADING, loading: false });
  const url = `${common.facultyDetailsByID}/${id}`;
  const response = Service.get(url, (status, response) => {
    dispatch({ type: LOADING, loading: false });
    //window.location.reload()
    return response;
  }).catch(er => {
    dispatch({ type: LOADING, loading: false });
    console.error('error', er.message)
  })
  return response;
};


const teacherUploadDoucmentDelete = (id) => async dispatch => {
  dispatch({ type: LOADING, loading: false });
  const url = `${common.teacherUploadDoucmentDelete}?id=${id}`;
  const response = Service.get(url, (status, response) => {
    dispatch({ type: LOADING, loading: false });
    store.addNotification({
      title: 'Success',
      message: `${response.error}`,
      type: 'success',
      container: 'top-right',
      dismiss: {
        duration: 3000,
      }
    })
    //window.location.reload()
    return response;
  }).catch(er => {
    dispatch({ type: LOADING, loading: false });
    console.error('error', er.message)
  })
  return response;
};

const getAllStudioProject = (center, user_id) => async dispatch => {
  dispatch({ type: LOADING, loading: false });
  const url = `${common.getAllStudioProject}?center=${center}&user_id=${user_id}`;
  const response = Service.get(url, (status, response) => {
    dispatch({ type: LOADING, loading: false });
    //window.location.reload()
    return response;
  }).catch(er => {
    dispatch({ type: LOADING, loading: false });
    console.error('error', er.message)
  })
  return response;
};

const getFilteredWiseStudioProject = (class_id, batch) => async dispatch => {
  dispatch({ type: LOADING, loading: false });
  console.log("batch", batch)
  const url = `${common.getAllStudioProject}?class=${class_id}&batch=${batch}`;
  const response = Service.get(url, (status, response) => {
    dispatch({ type: LOADING, loading: false });
    //window.location.reload()
    return response;
  }).catch(er => {
    dispatch({ type: LOADING, loading: false });
    console.error('error', er.message)
  })
  return response;
};

const deleteStudioProject = (project_id) => async dispatch => {
  dispatch({ type: LOADING, loading: false });
  const url = `${common.deleteStudioProject}${project_id}/`;
  const response = Service.get(url, (status, response) => {
    dispatch({ type: LOADING, loading: false });
    store.addNotification({
      title: 'Success',
      message: `${response.error}`,
      type: 'success',
      container: 'top-right',
      dismiss: {
        duration: 3000,
      }
    })
    window.location.reload()
    return response;
  }).catch(er => {
    dispatch({ type: LOADING, loading: false });
    console.error('error', er.message)
  })
  return response;
};

const curriculumDelete = (curriculum_id) => async dispatch => {
  dispatch({ type: LOADING, loading: false });
  const url = `${common.curriculumDelete}/${curriculum_id}`;
  const response = Service.get(url, (status, response) => {
    dispatch({ type: LOADING, loading: false });
    store.addNotification({
      title: 'Success',
      message: `${response.error}`,
      type: 'success',
      container: 'top-right',
      dismiss: {
        duration: 3000,
      }
    })
    return response;
  }).catch(er => {
    dispatch({ type: LOADING, loading: false });
    console.error('error', er.message)
  })
  return response;
};


const bulkUserJobID = (jobid) => async dispatch => {
  dispatch({ type: LOADING, loading: true });
  const url = `${common.bulkUserJobID}?task_id=${jobid}`;
  const response = Service.get(url, (status, response) => {
    dispatch({ type: LOADING, loading: false });
    store.addNotification({
      title: 'Success',
      message: `${response.error}`,
      type: 'success',
      container: 'top-right',
      dismiss: {
        duration: 3000,
      }
    })
    setTimeout(window.location.reload.bind(window.location), 1000);
    return response;
  }).catch(er => {
    dispatch({ type: LOADING, loading: false });
    // store.addNotification({
    //   title: 'Error',
    //   message: `${er.message}`,
    //   type: 'danger',
    //   container: 'top-right',
    //   dismiss: {
    //     duration: 3000
    //   }
    // })
    console.error('error', er.message[0]);
  })
  return response;
}

const bulkBatchJobID = (jobid) => async dispatch => {
  dispatch({ type: LOADING, loading: true });
  const url = `${common.bulkUserJobID}?task_id=${jobid}`;
  const response = Service.get(url, (status, response) => {
    dispatch({ type: LOADING, loading: false });
    store.addNotification({
      title: 'Success',
      message: `${response.error}`,
      type: 'success',
      container: 'top-right',
      dismiss: {
        duration: 3000,
      }
    })
    setTimeout(window.location.reload.bind(window.location), 1000);
    return response;
  }).catch(er => {
    dispatch({ type: LOADING, loading: false });
    console.error('error', er.message[0]);
  })
  return response;
}

const bulkQuestionJobID = (jobid) => async dispatch => {
  dispatch({ type: LOADING, loading: true });
  const url = `${common.bulkUserJobID}?task_id=${jobid}`;
  const response = Service.get(url, (status, response) => {
    dispatch({ type: LOADING, loading: false });
    store.addNotification({
      title: 'Success',
      message: `${response.error}`,
      type: 'success',
      container: 'top-right',
      dismiss: {
        duration: 3000,
      }
    })
    setTimeout(window.location.reload.bind(window.location), 1000);
    return response;
  }).catch(er => {
    dispatch({ type: LOADING, loading: false });
    console.error('error', er.message[0]);
  })
  return response;
}






const LearnerNotificationLogs = (is_read) => async dispatch => {
  dispatch({ type: LOADING, loading: false });
  const url = `${common.userNotificationLogs}?is_read=${is_read}`;
  const response = Service.get(url, (status, response) => {
    dispatch({ type: LOADING, loading: false });
    return response;
  }).catch(er => { dispatch({ type: LOADING, loading: false }); })
  return response;
}



const getStudentListIncreaseAttemptAssignment = (batch_id, assignment_id) => async dispatch => {
  dispatch({ type: LOADING, loading: true });
  const url = `${common.notificationStudentList}?batch_id=${batch_id}&&assignment_id=${assignment_id}`;
  const response = Service.get(url, (status, response) => {
    dispatch({ type: LOADING, loading: false });
    // store.addNotification({
    //   title: 'Success',
    //   message: `${response.error}`,
    //   type: 'success',
    //   container: 'top-right',
    //   dismiss: {
    //     duration: 3000,
    //   }
    // })
    return response;
  }).catch(er => {
    dispatch({ type: LOADING, loading: false });
    console.error('error', er.message[0]);
  })
  return response;
}

const getStudentListIncreaseAttemptAssessment = (batch_id, assessment_id, is_toc) => async dispatch => {
  dispatch({ type: LOADING, loading: true });
  const url = `${common.notificationStudentList}?batch_id=${batch_id}&&assessment_id=${assessment_id}&&is_toc=${is_toc}`;
  const response = Service.get(url, (status, response) => {
    dispatch({ type: LOADING, loading: false });
    // store.addNotification({
    //   title: 'Success',
    //   message: `${response.error}`,
    //   type: 'success',
    //   container: 'top-right',
    //   dismiss: {
    //     duration: 3000,
    //   }
    // })
    return response;
  }).catch(er => {
    dispatch({ type: LOADING, loading: false });
    console.error('error', er.message[0]);
  })
  return response;
}


const listUserGroups = (batch_id) => async dispatch => {
  dispatch({ type: LOADING, loading: true });
  const url = `${common.listUserGroups}`;
  let data = new FormData();
  data.append('batch_id', batch_id);
  if (batch_id !== null) {
    data.append('batch_id', batch_id);
  }
  const response = Service.post(url, data, (status, response) => {
    dispatch({ type: LOADING, loading: false });
    // store.addNotification({
    //   title: 'Success',
    //   message: `${response.error}`,
    //   type: 'success',
    //   container: 'top-right',
    //   dismiss: {
    //     duration: 3000,
    //   }
    // })
    return response;
  }).catch(er => {
    dispatch({ type: LOADING, loading: false });
    console.error('error', er.message[0]);
  })
  return response;
}



const getStudentSessionsList = (start_date, days, data_range, direction, if_loading, subject_id) => async dispatch => {
  dispatch({ type: LOADING, loading: if_loading });
  const url = `${common.studentLectures}`;
  const response = Service.post(url, { start_date, days, data_range, direction, if_loading, subject_id }, (status, response) => {
    dispatch({ type: LOADING, loading: false });
    return response;
  }).catch(er => { dispatch({ type: LOADING, loading: false }); })
  return response;
}

const getCertificateList = () => async dispatch => {
  dispatch({ type: LOADING, loading: true });
  const url = `${common.certificateListing}`;
  const response = Service.get(url, (status, response) => {
    dispatch({ type: LOADING, loading: false });
    return response;
  }).catch(er => { dispatch({ type: LOADING, loading: false }); })
  return response;
};


const getUserUploadCertificateList = () => async dispatch => {
  dispatch({ type: LOADING, loading: true });
  const url = `${common.getUploadCertificateList}`;
  const response = Service.get(url, (status, response) => {
    dispatch({ type: LOADING, loading: false });
    return response;
  }).catch(er => { dispatch({ type: LOADING, loading: false }); })
  return response;
};






export default {
  wpUserLink,
  searchUser,
  settingList,
  masterSettingList,
  formList,
  widgetList,
  widgetDelete,
  categoryList,
  assessmentQuestionDelete,
  programList,
  sessionList,
  assessmentQuestionDelete,
  fetchPromotionState,
  getWidgetDataById,
  instituteWidgetList,
  getMyCoachList,
  myCoursesList,
  openCourseLevels,
  openCourseSubjects,
  userdetail,
  studentQodListing,
  adminSearchList,
  qodStudentList,
  questionBankSearch,
  qodlist,
  getProfile,
  getCCS,
  getAdminList,
  getCenterList,
  getClassList,
  getClassList2,
  getMasterSubjects,
  getSubjectList,
  getSubjectList2,
  getFacultyList,
  getSectionList,
  getSectionList2,
  getStudentList,
  getTOC,
  getUnitTOC,
  getChapterTOC,
  getContentDetails,
  getAssessment,
  getAssessmentList,
  getDPPList,
  getPracticeSections,
  getAssessmentSectionList,
  getLectureList,
  getStudentDashboard,
  getFacultyDashboard,
  getStudentLectureList,
  getFacultyLectureList,
  getExerciseList,
  getStudentDPPList,
  getFacultyDPPList,
  getStudentTOC,
  getStudentDoubtList,
  getFacultyDoubtList,
  getStudentDoubtResponses,
  getFacultyDoubtResponses,
  getAssessmentFacultyReviewData,
  getFacultyExerciseList,
  getAssessmentReviewData,
  getAssessmentReviewAnalyticsData,
  getAssessmentFacultyReviewData,
  getScorecard,
  getFacultyScorecard,
  getwpUserToken,
  getWpCategoryList,
  wpKeyDecrypt,
  getQuestionsList,
  getSearchList,
  getCertificationBannerList,
  getGoalQuestionList,
  getMentorInterest,
  getTellInterest,
  userSearchList,
  getAssessmentsTypesList,
  callprofileApI,
  createLeadUser,
  switchClassTags,
  deleteBanner,
  getBatchHistory,
  getUserPortfolio,
  facultyDetailsByID,
  teacherUploadDoucmentDelete,
  getAllStudioProject,
  deleteStudioProject,
  getFilteredWiseStudioProject,
  curriculumDelete,
  bulkUserJobID,
  bulkBatchJobID,
  bulkQuestionJobID,
  LearnerNotificationLogs,
  getStudentListNotifications,
  getStudentListAssignmentNotifications,
  getStudentListIncreaseAttemptAssignment,
  getStudentListIncreaseAttemptAssessment,
  getStudentListActiveInactive,
  getBlogPost,
  listUserGroups,
  getElectiveStudentList,
  removeElectiveStudent,
  getStudentSessionsList,
  getCertificateList,
  getUserUploadCertificateList


}