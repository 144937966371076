import React, { Component } from 'react';
import { connect } from 'react-redux';
import Popup from 'reactjs-popup';
import { Helmet } from "react-helmet";
import ReactNotifications from 'react-notifications-component';
import Shimmer from 'react-js-loading-shimmer';
import { Link } from 'react-router-dom';
import actions from '../../redux/actions';
import Add from '../../public/icons/add.svg';
import List from '../../public/icons/list.svg';
import Grid from '../../public/icons/grid.svg';
import Edit from '../../public/icons/edit.svg';
import Delete from '../../public/icons/delete.svg';
import Trash from '../../public/icons/trash.svg';
import Header from '../../components/navigation/Header';
import Empty from '../../components/error/Empty';
import Back from '../../public/icons/back.svg';
import mapIcon from '../../public/icons/map-icon.svg';
import 'react-notifications-component/dist/theme.css';
import './styles.css';
import DownloadArrow from '../../public/icons/down-arrow.svg';
import sampleBatchUpdateFile from '../../public/images/bulk_batch_assign.csv';
import reportsLoader from '../../public/icons/loader.png';

class ElectiveSubjects extends Component {
  constructor(props) {
    super(props);
    this.state = {
      d_id: null,
      open: false,
      open2: false,
      opend: false,
      section_id: null,
      batch_id: this.props.location.state?.batch_id,
      classs: parseInt(this.props.match.params.classs),
      class_id: this.props.location.state?.class_id,
      name: '',
      code: '',
      order: '',
      description: '',
      page_url: this.props.location.pathname,
      class_label: this.props.location.state?.class_label,
      center_id: this.props.location.state?.center_id,
      list: localStorage.getItem('preferredGrid') === 'false' ? true : false,
      classes: [],
      sections: [],
      is_faculty_admin: false,
      session: 0,
      getlistingData: [],
      loaderShow: true,

      openDialog: false,
      openThanksDialog: false,
      groupTitle: '',
      numberOfGroups: 0,
      noOfStudents: this.props.location.state?.no_of_students,
      session_id: this.props.location.state?.session_id,
      username: localStorage.getItem('name'),
      center_id: localStorage.getItem('center_id'),
      groupPurpose: '',
      breakOutRoomName: '',
      csvFileName: '',
      csvFileOnly: '',
      groupFileError: false,
      studentList: null,
      selected_class_id: null,
      selected_section_id: null,
      removed_subjects_details: [],
      removed_subjects_details_payload: []
    }
  }

  componentDidMount() {
    localStorage.setItem("page_url", this.state.page_url)
    this.getClassList();
  }


  getClassList = async () => {
    const response = await this.props.getClassList();
    if (response !== undefined) {
      this.setState({ classes: response?.data });

    }
  }

  handleClassChange = async (e) => {
    this.setState({ selected_class_id: e.target.value })
    const response = await this.props.getSectionList2(this.state.center_id, e.target.value)

    if (response !== undefined) {
      this.setState({ sections: response?.data });
    }
  }
  handleSectionChange = async (e) => {
    this.setState({ selected_section_id: e.target.value })
    const response = await this.props.getElectiveStudentList(this.state.center_id, this.state.selected_class_id, e.target.value)
    if (response != undefined) {
      this.setState({ studentList: response?.data?.data })
    }

  }


  handleSubjectMappingChange = (student_id, subject_id) => {
    let removedSubjects = { ...this.state.removed_subjects_details };

    // Initialize the student's array if it doesn't exist
    if (!removedSubjects[student_id]) {
      removedSubjects[student_id] = [];
    }

    let studentSubjects = removedSubjects[student_id];


    if (studentSubjects.includes(subject_id)) {
      studentSubjects = studentSubjects.filter((id) => id !== subject_id);
      console.log(studentSubjects);
    } else {
      studentSubjects.push(subject_id);
      console.log(studentSubjects);
    }

    // Update the mapping for the student
    removedSubjects[student_id] = studentSubjects;

    // Update the state with the modified object
    this.setState({ removed_subjects_details: removedSubjects });
    console.log(removedSubjects)


    let finalList = []

    Object.keys(removedSubjects).forEach((key) => {
      let data = {
        "student_id": parseInt(key),
        "subject_ids": removedSubjects[key]
      }
      finalList.push(data)
    })
    console.log(finalList)
    this.setState({ removed_subjects_details_payload: finalList })


  }

  handleSubjectMapping = async () => {
    const response =await  this.props.removeElectiveStudent(this.state.removed_subjects_details_payload)
    console.log(response)
  }

  render() {
    const isEnabled = this.state.groupPurpose && this.state.breakOutRoomName && this.state.csvFileName && this.state.groupFileError == '';




    return (
      <div className="admin-div">
        <Helmet>
          <title>{`Open Grpup Listing`}</title>
        </Helmet>
        <ReactNotifications />
        <Header placeholder={this.props.pagewiseLanguageData.search_sections} />

        <div className="main-part">
          <div className="sub-head">
            <div className="heading marginBottomZero directionChange">
              <h1>Elective Subjects</h1>
              <p>Student's subjects can be managed from this screen</p>
            </div>

          </div>

          <div className='electiveStudentsWrapper'>

            <h3>Update Student Subjects</h3>

            <div className='filtersWrapper'>
              <div className='block'>
                <label>Select class</label>
                <select onChange={this.handleClassChange}>
                  <option value="0">Select Class</option>
                  {this.state.classes.map((classs, index) =>
                    <option value={classs.id}> {classs.label}</option>
                  )};
                </select>


              </div>
              <div className='block'>
                <label>Select Section</label>
                <select onChange={this.handleSectionChange}>
                  <option value="0">Select Section</option>
                  {this.state.sections.map((section, index) =>
                    <option value={section.id}> {section.label}</option>
                  )};
                </select>
              </div>
            </div>

            <div className='studentListingBlock'>



              {this.state.studentList && <table>
                <thead>
                  <tr>
                    <th>Student Name</th>
                    {this.state.studentList[0].subjects_details.map((subject, index) => (
                      <th key={index}>{subject.label}</th>
                    ))}
                  </tr>
                </thead>

                <tbody>
                  {this.state.studentList.map((student, studentIndex) => (
                    <tr key={student?.user?.id}>
                      <td>{student?.user?.name}</td>
                      {student.subjects_details.map((subject, subjectIndex) => (
                        <td key={subject.id}>

                          <input
                            type="checkbox"
                            checked={this.state.removed_subjects_details[student?.user?.id]?.includes(subject.id) || false}
                            onChange={(e) => {
                              this.handleSubjectMappingChange(student?.user?.id, subject.id)
                            }}
                          />
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
              
            
              }
              {this.state.removed_subjects_details_payload.length > 0 && <div className='actions'><button onClick={this.handleSubjectMapping}>Save</button></div> }


            </div>

          </div>

        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.commonReducer.loading,
    pagewiseLanguageData: state.commonReducer.pagewiseLanguageData
  }
}

const mapDispatchToProps = (dispatch) => {
  const { createSection, updateSection, getSectionList, getClassList, getPageLanguageWiseData, breakoutRoomListing, createBreakoutRoom, getSectionList2, getElectiveStudentList, removeElectiveStudent } = actions;
  return {
    getSectionList: (center_id, class_id, show_all, page) => dispatch(getSectionList(center_id, class_id, show_all, page)),
    getClassList: () => dispatch(getClassList()),
    getPageLanguageWiseData: (language, page) => dispatch(getPageLanguageWiseData(language, page)),
    getSectionList2: (center_id, class_id) => dispatch(getSectionList2(center_id, class_id)),
    getElectiveStudentList: (center_id, class_id, section_id) => dispatch(getElectiveStudentList(center_id, class_id, section_id)),
    removeElectiveStudent: (student_list) => dispatch(removeElectiveStudent(student_list)),


  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ElectiveSubjects);
