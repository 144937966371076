// src/components/AttendanceReport.js
import './AttendanceReport.css';
import React, { useEffect, useState } from 'react';
import actions from '../../redux/actions';
import { connect } from 'react-redux';

const UserAttendanceReport = (props) => {
    const [selectedOrganization, setSelectedOrganization] = useState('');
    const [selectedDate, setSelectedDate] = useState('');
    const { studentAttendanceReport, getstudentAttendanceReport } = props;
    const classes = JSON.parse(localStorage.getItem('logindetails'))?.classes
    const data = [
        {
            enrollmentNo: '123456',
            rollNo: '01',
            studentName: 'John Doe',
            email: 'john.doe@example.com',
            courseName: 'Computer Science',
            subjectName: 'Mathematics',
            attendance: [1, 0, 1, 1, 0, 1, 1],
            expectedAttendance: 31,
        },
        // Add more students data here
    ];

    const onClassChange = (e) => {
        //     if(e.target.value !== '') {
        //       this.setState({ subjectsFilter: this.state.subjects.filter(subject => 
        //                         subject.class_id === parseInt(e.target.value)), 
        //                         classs: parseInt(e.target.value), batch: 0, subject: 0,
        //                         batchesFilter: this.state.batches.filter(batch => batch.classs?.id === parseInt(e.target.value)) })
        //     }
        //     else {
        //       this.setState({ subjectsFilter: this.state.subjects, subject: 0,
        //                         batchesFilter: this.state.batches, batch: 0 });
        //     }
    }


    useEffect(() => {
        const getAllAttendaceReportList = async () => {
            try {
                const response = await getstudentAttendanceReport();
                if (response && response.data) {
                    getAllAttendaceReportList(response.data);
                } else {
                    console.error("Unexpected response structure:", response);
                }
            } catch (error) {
                console.error("Failed to fetch attendance report list:", error);
            }
        };
        getAllAttendaceReportList();
    }, [getstudentAttendanceReport]);

    return (
        <div className="admin-div">
            <div className="main-part">
                <div className="sub-head">
                    <div className="attendance-report">
                        <h1>Attendance Report</h1>
                        <hr />
                        <div className="dropdown-container">



                            <select onChange={onClassChange}>
                                <option value={parseInt(0)}>
                                    Select Class
                                </option>
                                {classes && classes.map(classs =>
                                    <option value={parseInt(classs.class_id)} key={classs.class_id}>
                                        {classs.class_name}
                                    </option>
                                )}
                            </select>
                            <select
                                id="class-select"
                                value={selectedOrganization}
                                onChange={(e) => setSelectedOrganization(e.target.value)}
                            >
                                <option value="">Select Class</option>
                                <option value="Organizational Development and Change Management">
                                    Organizational Development and Change Management
                                </option>
                                {/* Add more organization options here */}
                            </select>


                            <select
                                id="batch-select"
                                value={selectedDate}
                                onChange={(e) => setSelectedDate(e.target.value)}
                            >
                                <option value="">Select Batch</option>
                                <option value="Batch">22 March</option>
                                {/* Add more batch options here */}
                            </select>

                            <select
                                id="subject-select"
                                value={selectedDate}
                                onChange={(e) => setSelectedDate(e.target.value)}
                            >
                                <option value="">Select Subject</option>
                                <option value="Subject">Mathematics</option>
                                {/* Add more subject options here */}
                            </select>

                            <select
                                id="sdate-select"
                                value={selectedDate}
                                onChange={(e) => setSelectedDate(e.target.value)}
                            >
                                <option value="">Select Date</option>
                                <option value="22 March">22 March</option>
                                {/* Add more date options here */}
                            </select>
                        </div>
                        <table>
                            <thead>
                                <tr>
                                    <th>Enrollment No</th>
                                    <th>Roll No</th>
                                    <th>Student Name</th>
                                    <th>Email</th>
                                    <th>Course Name</th>
                                    <th>Subject Name</th>
                                    {[...Array(10).keys()].map(i => (
                                        <th key={i}>Date {i + 1}</th>
                                    ))}
                                    <th>Expected Attendance in Days</th>
                                    <th>No of Days Present</th>
                                    <th>Present %age</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data.map((student, index) => {
                                    const presentDays = student.attendance.reduce((sum, day) => sum + day, 0);
                                    const presentPercentage = ((presentDays / student.expectedAttendance) * 100).toFixed(2);

                                    return (
                                        <tr key={index}>
                                            <td>{student.enrollmentNo}</td>
                                            <td>{student.rollNo}</td>
                                            <td>{student.studentName}</td>
                                            <td>{student.email}</td>
                                            <td>{student.courseName}</td>
                                            <td>{student.subjectName}</td>
                                            {student.attendance.map((day, idx) => (
                                                <td key={idx}>{day ? 'P' : 'A'}</td>
                                            ))}
                                            <td>{student.expectedAttendance}</td>
                                            <td>{presentDays}</td>
                                            <td>{presentPercentage}%</td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => ({
    // Map your state to props if needed
});

const mapDispatchToProps = (dispatch) => {
    const { studentAttendanceReport } = actions;
    return {
        studentAttendanceReport: (subject_id, classs_id, batch_id, date) => dispatch(studentAttendanceReport(subject_id, classs_id, batch_id, date)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserAttendanceReport);
