import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Helmet } from "react-helmet";
import ReactNotifications, { store } from 'react-notifications-component';

import actions from '../../redux/actions';
import common from '../../common';
import ImgHolder from '../../public/images/img-holder.svg';
import Header from '../../components/navigation/Header';
import 'react-notifications-component/dist/theme.css';
import './styles.css';

class Institute extends Component {
  constructor(props) {
    super(props);
    this.state = {
      center_id: localStorage.getItem('center_id'),
      image: null,
      profile: {}
    }
  }

  componentDidMount() {
    console.log("landing testing");
    this.getProfile();
    this.getUrlfromCurrentPage();

    //New Code For Change Theme Setting Based Onb Login start
    let getCurrentCenterID = localStorage.getItem('center_id');
    let saveBeforeLoginSetting = localStorage.getItem('loginPageSettings');
    let getBeforeCenterID =  JSON.parse(saveBeforeLoginSetting).length == 0 ? getCurrentCenterID : JSON.parse(saveBeforeLoginSetting)[0]['center_id'];
    console.log("getCurrentCenterID", getCurrentCenterID)
   console.log("saveBeforeLoginSetting", saveBeforeLoginSetting)
   console.log("getBeforeCenterID", getBeforeCenterID)

   if(getCurrentCenterID != getBeforeCenterID){
    console.log("getBeforeCenterID =  nahi hai");
    let getNewResponse = JSON.parse(localStorage.getItem('logindetails'));
    let fetchPrimaryColor = getNewResponse.institute_settings.primary_color;
    let fetchPrimaryColorLight = getNewResponse.institute_settings.primary_color_light;
    let fetchSecondaryColor = getNewResponse.institute_settings.secondary_color;
    let fetchLogoUrl = getNewResponse.institute_settings.logo_url;
    let fetchLoginBannerUrl = getNewResponse.institute_settings.login_banner;
    
    if(fetchPrimaryColor !== 'undefined'){
      localStorage.setItem('primary_color', fetchPrimaryColor);
      document.documentElement.style.setProperty('--primary', localStorage.getItem('primary_color'));
    }
    if(fetchPrimaryColorLight !== 'undefined'){
      localStorage.setItem('primary_color_light', fetchPrimaryColorLight);
      document.documentElement.style.setProperty('--primary-rgba',localStorage.getItem('primary_color_light'));
   
    }
    if(fetchSecondaryColor !== 'undefined'){
      localStorage.setItem('secondary_color', fetchSecondaryColor);
      document.documentElement.style.setProperty('--secondary',localStorage.getItem('secondary_color'));
   
    }

    if(fetchLogoUrl !== 'undefined'){
      localStorage.setItem('logo_url', fetchLogoUrl);
    }

    if(fetchLoginBannerUrl !== 'undefined'){
      localStorage.setItem('login_banner', JSON.stringify(fetchLoginBannerUrl));
    }

  }
    //New Code For Change Theme Setting Based Onb Login ends


    
  }

  getUrlfromCurrentPage = ()=>{
    let getUrl = this.props.location.pathname;
    let modifyURL = getUrl.split("/").pop();
    let language = localStorage.getItem('lang');
    if(modifyURL){
      console.log("modifyURL", modifyURL);
      console.log("languagessss",language);
      this.fetchPageWiseData(language,modifyURL);
    }
    
  }

  fetchPageWiseData = async (language,modifyURL) =>{
    const response = await this.props.getPageLanguageWiseData(language,modifyURL);
    if(response !== undefined && response.status == 1) {
      console.log("getPageLanguageWiseData");
      console.log(response?.data);
      localStorage.setItem('pageLanguageWiseData',JSON.stringify(response?.data))
    }
  }

  getProfile = async () => {
    const response = await this.props.getProfile(this.state.center_id);
    if(response !== undefined) {
      this.setState({ image: response.data.logo, profile: response.data });
    }
  }
  
  handleChange = (event) => {
    if(event.target.files[0].type.split('/')[0] === 'image') {
      this.editCenter(event.target.files[0]);
    }
    else {
      document.getElementById('img-input').value = "";
      store.addNotification({
        title: 'Error',
        message: `${this.props.pagewiseLanguageData.only_image_can_be_uploaded}`,
        type: 'danger',
        container: 'top-right',
        dismiss: {
          duration: 3000
        }
      })
    }
  }

  editCenter = async (image) => {
    const { center_id, profile } = this.state;
    await this.props.updateCenter( center_id, profile.name, profile.code, profile.country,
                                     profile.state, profile.city, image, null);
  }

	render() {
    if(this.props.loading) {
      return (
        <div className="admin-div">
        </div>
      )
    }
		return (
			<div className="admin-div">
        <Helmet>
          <title>Institute</title>
        </Helmet>
        <ReactNotifications/>
				<Header placeholder="Search institutes"/>
        <div className="institute-div">
          <div id="congo-span">
            {this.props.pagewiseLanguageData.congratulations}! {this.state.profile.name}
          </div>
          <span>{this.props.pagewiseLanguageData.you_have_your_own_lms_now}</span>
          <input style={{display: 'none'}} accept="image/*" type="file" id="img-input"
            onChange={this.handleChange} ref={fileInput => this.fileInput = fileInput}/>
          {this.state.image === '' || this.state.image === null 
            ? <img src={ImgHolder} alt="img" onClick={() => this.fileInput.click()}/>
            : <div className="institute-img" onClick={() => this.fileInput.click()}
            style={{backgroundImage: "url("+common.addFileUrl(this.state.image)+")"}}></div>}
          <p>{this.props.pagewiseLanguageData.have_your_personalised_application}, <br/>{this.props.pagewiseLanguageData.click_on_imageand_Upload_logo_as_it_will_be_visible_as_brand_identity}</p>
        </div>
			</div>
		)
	}
}

const mapStateToProps = (state) => {
  return {
    loading: state.commonReducer.loading,
    currentPage:state.commonReducer.currentPage,
    pagewiseLanguageData : state.commonReducer.pagewiseLanguageData
  }
}

const mapDispatchToProps = (dispatch) => {
  const { updateCenter, getProfile,getPageLanguageWiseData} = actions;
  return {
    updateCenter: (center_id, name, code, country, state, city, logo, admin) => dispatch(updateCenter(center_id, name, code, country, state, city, logo, admin)),
    getProfile: (center_id) => dispatch(getProfile(center_id)),
    getPageLanguageWiseData: (language,page) => dispatch(getPageLanguageWiseData(language,page)),
   
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Institute);